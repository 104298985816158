import React from "react";
import { Link } from "react-router-dom";
import mail from "../../images/icon/mail.png";
import payment from "../../images/logo card.png";
import OfferFeaturesSection from "../OfferFeaturesSection/OfferFeaturesSection";
import Logo from "../UiStyle/Logo";
import Discountbar from "../Discountbar/Discountbar";
import HeaderTopbar from "../HeaderTopbar/HeaderTopbar";
import { connect } from "react-redux";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const SubmitHandler = (e) => {
  e.preventDefault();
};

const Footer = (props) => {
  const { configs } = props;
  const email_support = configs?.admin_conditions?.email_support || "";

  return (
    <>
      {/* <Discountbar /> */}
      <HeaderTopbar />
      <footer className={`footer-section ${props.ftClass}`}>
        {/* <OfferFeaturesSection /> */}
        <div className="upper-footer">
          <div className="container">
            <div className="row">
              <div className="col col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="widget about-widget">
                  <div className="widget-logo">
                    <Logo to={"/"} />
                  </div>
                  <p>
                    Welcome to Chemist For All, your trusted online destination for all your healthcare needs. We specialize in providing a wide range of
                    high-quality medicines, health supplements, and wellness products, delivered right to your doorstep..{" "}
                  </p>
                  <div>
                  <ul className="">
                    <li>
                      <i className="mr-2">
                        <img src={mail} alt="" />
                      </i>
                      Email: {` ${email_support}`}
                    </li>
                  </ul>
                </div>
                </div>
              </div>
              {/* <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Information</h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/shop">
                      Delivery
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/about">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/checkout">
                      Secure Payment
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/contact">
                      Sitemap
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/shop">
                      Stores
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
              <div className="col col-xl-4  col-lg-4 col-md-4 col-sm-12 col-12 "></div>
              <div className="col col-xl-4  col-lg-4 col-md-4 col-sm-12 col-12 ">
                <div className="widget link-widget custom">
                  <div className="widget-title">
                    <h3>Custom Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/shop">
                        New Products
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/shop">
                        Best Sales
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/login">
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/login">
                        My Account
                      </Link>
                    </li>
                    
                  </ul>
                </div>
              </div>
              {/* <div className="col col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i>
                        <img src={mail} alt="" />
                      </i>
                      Email: {` ${email_support}`}
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div className="col col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget subscribe">
                                <div className="widget-title">
                                    <h3>Newsletter</h3>
                                </div>
                                <p>I have been a loyal customer of this auto parts company for years and I cannot
                                    recommend them enough. </p>
                                <form onSubmit={SubmitHandler}>
                                    <div className="form-field">
                                        <input type="email" placeholder="Enter your mail" id="semail" required />
                                        <button type="submit" className="btn-style-1">Submit Now</button>
                                    </div>
                                </form>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
        <div className="lower-footer">
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-lg-4 col-12">
                <div className="copy-left">
                  <p className="copyright">2024 &copy; all right reserved by Chemist For All</p>
                </div>
              
              </div>
              
              <div className="col col-lg-8 col-12">
                <ul className="lower-footer-link">
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      <img src={payment} alt="" height={"30px"} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
    w_list: state.wishList.w_list,
    configs: state.configs,
  };
};
export default connect(mapStateToProps)(Footer);