import React, { useEffect, useMemo, useState } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import TimeCountDown from "../countdown";
import axios from "axios";
import { toast } from "react-toastify";
import { Grid, Pagination, Rating } from "@mui/material";
import { checkLoggedIn } from "../../utils";
import DeleteIcon from "../../icons/DeleteIcon";
import HorizontalLoadingCard from "../../components/Cards/HorizontalLoadingCard";
import CustomPaginationButton from "../UI/Button/CustomPaginationButton";
import { MoonLoader } from "react-spinners";
import {
  ShimmerButton,
  ShimmerThumbnail,
  ShimmerTitle,
} from "react-shimmer-effects";

const DealProduct = ({
  addToCartProduct,
  addToWishListProduct,
  addToCompareListProduct,
  configs,
  ...props
}) => {
  const [dealZone, setDelaZone] = useState([]);
  const discount = configs?.admin_conditions?.discount || "";

  const [filter, setFilter] = useState({
    page: 1,
    limit: 4,
    totalRecords: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handlePageChange = (event, value) => {
    setFilter((prev) => ({ ...prev, page: value }));
  };

  const fetchPopularcategories = () => {
    setLoading(true);
    if (error) setError(false);
    axios
      .get("get-products", {
        params: { dealzone: true, ...filter },
      })
      .then((response) => {
        const data = response?.data?.data;
        setDelaZone(data?.map((el) => ({ ...el, id: el._id })));
        setFilter((prev) => ({
          ...prev,
          totalRecords: response?.data?.totalRecords,
        }));
      })
      .catch((error) => {
        setDelaZone([]);
        setError(true);
        toast.error(error.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPopularcategories();
  }, [filter.page]);

  return (
    <section className="deal-product-section">
      <div className="container sortable-gallery">
        <div className="deal-product-title">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <SectionTitle sTitle={"Attention! Deal Zone Here"} />
            </div>
            <div className="col-lg-4">
              <div className="count-up">
                {discount ? (
                  <p> Save {discount}% on every product, every time you shop! </p>
                ) : (
                  <div className="product-info w-full">
                    <ShimmerTitle
                      className="mb-0"
                      variant={"secondary"}
                      line={2}
                      gap={10}
                    />
                  </div>
                )}
                {/* <TimeCountDown /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="deal-wrap relative">
          <div className="deal-active grid-2">
            {loading ? (
              <>
                {new Array(filter?.limit || 8).fill(1)?.map((el) => {
                  return (
                    <div className="deal-item p-0">
                      <div className="deal-image">
                        <ShimmerThumbnail
                          width={200}
                          height={200}
                          className="m-0"
                        />
                      </div>
                      <div className="product-info pl-10 p-10 w-full ml-10 h-220">
                        <ShimmerTitle line={4} gap={20} className={"mb-0"} />
                      </div>
                    </div>
                  );
                })}
                <div className="flex-justify-center-items-center">
                  <Pagination
                    disabled={true}
                    count={Math.ceil(filter?.totalRecords / filter?.limit)}
                    size={"large"}
                    page={filter?.page}
                    defaultPage={filter?.page}
                    onChange={handlePageChange}
                    siblingCount={0}
                    renderItem={(item) => {
                      return (
                        <CustomPaginationButton item={item} loading={loading} />
                      );
                    }}
                  />
                </div>
              </>
            ) : dealZone.length === 0 ? (
              <div>
                <h3>Oops! No Data Found!</h3>
              </div>
            ) : (
              <>
                {dealZone?.length > 0 &&
                  dealZone?.map((product, pitem) => (
                    <DealZoneCard
                      product={product}
                      addToCartProduct={addToCartProduct}
                      {...props}
                    />
                  ))}
                <div className="flex-justify-center-items-center">
                  <Pagination
                    count={Math.ceil(filter?.totalRecords / filter?.limit)}
                    size={"large"}
                    page={filter?.page}
                    defaultPage={filter?.page}
                    onChange={handlePageChange}
                    siblingCount={0}
                    renderItem={(item) => {
                      return <CustomPaginationButton item={item} />;
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DealProduct;

const DealZoneCard = ({
  product,
  addToWishListProduct,
  addToCartProduct,
  handleClickOpen,
  carts,
  removeFromCart,
  decrementQuantity,
  incrementQuantity,
  className,
  w_list,
  ...props
}) => {
  // Fetch the loading state from the Redux store
  const [loadingState, setLoadingState] = useState({
    buyNow: false,
    addToCart: false,
    increment: false,
    decrement: false,
  }); // Separate loading states;
  const navigate = useNavigate();

  const inCart = useMemo(() => {
    return carts.find((cart) => cart.id === product?.id);
  }, [carts, product]);

  // Function to handle adding to cart
  const handleAddToCart = async () => {
    if (checkLoggedIn()) {
      setLoadingState({ ...loadingState, addToCart: true }); // Start loading
      await addToCartProduct(product); // Wait for the API call to complete
      setLoadingState({ ...loadingState, addToCart: false }); // Stop loading
    } else {
      setLoadingState({ ...loadingState, addToCart: true }); // Start loading
      setTimeout(() => {
        addToCartProduct(product); // Adding Data in Redux
        setLoadingState({ ...loadingState, addToCart: false }); // Stop loading
      }, 1000);
    }
  };

  // Function to handle incrementing quantity
  const handleIncrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoadingState({ ...loadingState, increment: true }); // Start loading
      await incrementQuantity(product.id); // Wait for the API call to complete
      setLoadingState({ ...loadingState, increment: false }); // Stop loading
    } else {
      setLoadingState({ ...loadingState, increment: true }); // Start loading
      setTimeout(() => {
        incrementQuantity(product.id);
        setLoadingState({ ...loadingState, increment: false }); // Stop loading
      }, 1000);
    }
  };

  // Function to handle decrementing quantity or removing from cart
  const handleDecrementQuantity = async () => {
    if (checkLoggedIn()) {
      setLoadingState({ ...loadingState, decrement: true }); // Start loading
      if (inCart.qty === 1) {
        await removeFromCart(product.id);
      } else {
        await decrementQuantity(product.id);
      }
      setLoadingState({ ...loadingState, decrement: false }); // Stop loading
    } else {
      setLoadingState({ ...loadingState, decrement: true }); // Start loading
      if (inCart.qty === 1) {
        setTimeout(() => {
          removeFromCart(product.id);
          setLoadingState({ ...loadingState, decrement: false }); // Stop loading
        }, 1000);
      } else {
        setTimeout(() => {
          decrementQuantity(product.id);
          setLoadingState({ ...loadingState, decrement: false }); // Stop loading
        }, 1000);
      }
    }
  };

  return (
    <div className="deal-item p-0">
      <div className="deal-image">
        <img src={product?.proimg?.[0]?.src} alt="" />
      </div>
      <div className="product-info pl-10 p-10 w-full ml-10 h-220">
        <h2>
          <Link
            //   onClick={ClickHandler}
            to={`/product-single/${product?.slug}`}
          >
            {product.title}
          </Link>
        </h2>
        <div className="flex items-center gap-10 mb-10">
          <Rating
            name="read-only"
            precision={0.5}
            value={+product?.five_start_rating}
            readOnly
            className="mt-2"
          />{" "}
          <span className="mt-2">{product?.five_start_rating || 0}</span>
        </div>

        <div className="w-full">
          <h6>Category : {product?.category}</h6>
        </div>
        <div className="w-full">
          <h6>Brand : {product?.brand}</h6>
        </div>

        <div className="product-card-footer">
          <div className="w-full">
            <span className="present-price">${product?.price}</span>
            <del className="old-price">${product?.delPrice}</del>
          </div>
          {inCart ? (
            <Grid className="flex justify-end items-center gap-10" container>
              <button
                className="decQtybutton"
                onClick={handleDecrementQuantity}
                disabled={
                  loadingState?.buyNow ||
                  loadingState?.addToCart ||
                  loadingState?.increment ||
                  loadingState?.decrement
                } // Disable all other buttons while loading
              >
                {loadingState?.decrement ? (
                  <MoonLoader size={16} color="#fafafa" /> // Show spinner
                ) : inCart.qty === 1 ? (
                  <DeleteIcon />
                ) : (
                  "-"
                )}
              </button>
              <h2>{inCart?.qty}</h2>
              <button
                className="incQtybutton"
                onClick={handleIncrementQuantity}
                disabled={
                  loadingState?.buyNow ||
                  loadingState?.addToCart ||
                  loadingState?.increment ||
                  loadingState?.decrement
                } // Disable all other buttons while loading // Disable button while loading
              >
                {loadingState?.increment ? (
                  <MoonLoader size={16} color="#fafafa" />
                ) : (
                  "+"
                )}
              </button>
            </Grid>
          ) : (
            <button
              className="cart-btn-full"
              data-tooltip-id="cart-tooltip"
              data-tooltip-content="add to cart"
              data-tooltip-place="right"
              onClick={handleAddToCart}
              disabled={
                loadingState?.buyNow ||
                loadingState?.addToCart ||
                loadingState?.increment ||
                loadingState?.decrement
              } // Disable all other buttons while loading // Disable button while loading
            >
              {loadingState?.addToCart ? (
                <MoonLoader size={16} color="#fafafa" />
              ) : (
                <i className="icon-cart"></i>
              )}
            </button>
          )}
          <Tooltip id="cart-tooltip" />
        </div>
      </div>
    </div>
  );
};
